<template>
  <ion-page>
    <ion-content fullscreen="true">
      <form @submit.prevent="submit">
        <ion-thumbnail>
          <ion-icon :icon="personAddOutline"></ion-icon>
        </ion-thumbnail>
        <h3>{{ $t("Add a dependant") }}</h3>
        <p>
          {{
            $t(
              "Enter the email address of the dependent to send your application."
            )
          }}
        </p>
        <ion-item>
          <ion-input
            v-model="email"
            name="email"
            type="email"
            inputmode="email"
            :placeholder="emailPlaceholder"
            @change="v$.email.$touch()"
          ></ion-input>
        </ion-item>
        <template v-if="v$.email.$error">
          <p v-for="(error, i) of v$.email.$errors" :key="i" class="error">
            {{ $t(error.$message) }}
          </p>
        </template>
        <ion-button type="submit" expand="block">{{
          $t("Send Application")
        }}</ion-button>
      </form>
      <div v-if="!user.solicitudes.length" class="empty-state">
        <p>{{ $t("You have not sent any application") }}</p>
        <img src="/assets/apply-for-dependant.svg" />
      </div>
      <div v-else class="applications">
        <p>{{ $t("Applications sent") }}</p>
        <ion-item v-for="application in user.solicitudes" :key="application.id">
          <img src="/assets/avatar-application.svg" slot="start" />
          {{ application.email }}
          <ion-button
            fill="outline"
            slot="end"
            @click.prevent="discard(application.email)"
            >{{ $t("Dismiss") }}</ion-button
          >
        </ion-item>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonPage,
  IonContent,
  IonItem,
  IonInput,
  IonButton,
  IonThumbnail,
  IonIcon,
  loadingController,
  alertController,
  toastController
} from "@ionic/vue";
import { personAddOutline } from "ionicons/icons";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, email as validateEmail } from "@vuelidate/validators";
import { useI18n } from "vue-i18n";

export default {
  name: "FulfillUserType",
  components: {
    IonPage,
    IonContent,
    IonItem,
    IonInput,
    IonButton,
    IonThumbnail,
    IonIcon
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const error = computed(() => store.state.auth.error);
    const email = ref(null);
    const emailPlaceholder = t("Dependant's E-mail");
    const v$ = useVuelidate({ email: { required, validateEmail } }, { email });
    const showError = async () => {
      const alert = await alertController.create({
        cssClass: "error-alert",
        header: t("Error"),
        message: t(
          error.value
            ? error.value.join("<br>")
            : "There was an error with the request. Please, try again."
        ),
        buttons: [t("Ok")]
      });
      alert.present();
      store.dispatch("auth/clearError");
    };
    const submit = async () => {
      const sending = await loadingController.create({
        message: t("Sending...")
      });
      await sending.present();
      try {
        if (await store.dispatch("auth/sendApplication", email.value)) {
          sending.dismiss();
          const ok = await alertController.create({
            header: t("Invitation sent"),
            message: t(
              "Your invitation to {email} has been sent. We will notify you when it's aproved.",
              { email: email.value }
            ),
            buttons: [t("Ok")]
          });
          ok.present();
          email.value = "";
          return true;
        }
      } catch (e) {
        console.log(e);
      }
      sending.dismiss();
      showError();
    };
    const discard = async emailToDiscard => {
      const sending = await loadingController.create({
        message: t("Sending...")
      });
      await sending.present();
      try {
        if (await store.dispatch("auth/discardApplication", emailToDiscard)) {
          sending.dismiss();
          const ok = await toastController.create({
            message: t("Dismissed"),
            duration: 2000
          });
          ok.present();
          return true;
        }
      } catch (e) {
        console.log(e);
      }
      sending.dismiss();
      showError();
    };
    return {
      user: computed(() => store.state.auth.user),
      personAddOutline,
      email,
      emailPlaceholder,
      v$,
      submit,
      discard,
      error
    };
  }
};
</script>
<style scoped>
form {
  background: var(--ion-color-light);
  padding: 20px 15px;
  text-align: center;
}
form ion-thumbnail {
  margin: 20px auto auto;
  background: white;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  padding: 25px 20px 15px;
}
form ion-icon {
  width: 40px;
  height: 40px;
  color: var(--ion-color-medium);
}
form p {
  color: var(--ion-color-medium);
  margin-bottom: 1.5em;
}
form ion-item {
  --inner-padding-bottom: 0;
  --inner-padding-end: 0.5em;
  --inner-padding-start: 0.5em;
  --inner-padding-top: 0;
  -padding-top: 0;
}
form ion-input {
  --padding-bottom: 0.2em;
  --padding-end: 1em;
  --padding-start: 1em;
  --padding-top: 0.2em;
}
form ion-item + ion-button {
  margin-top: 1em;
}
form p.error {
  margin-bottom: 1em;
}
.applications p {
  padding: 1rem;
  line-height: 1;
  margin: 0.5rem 0 0;
}
.applications img {
  margin-left: 1rem;
  width: 40px;
  height: 40px;
}
.applications ion-item {
  font-size: 0.9em;
  --inner-padding-top: 0.75em;
  --inner-padding-bottom: 0.75em;
  --color: var(--ion-color-medium);
  --border-color: var(--ion-color-light-shade);
}
</style>
